
import Api2Talk from "../api2talk";

const PATH = '/chatbot-flow';

const create = async (input) => { 
  try {
    const response = await Api2Talk.post(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (input) => { 
  try {
    const response = await Api2Talk.put(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPaginate = async (page, chatbotid) => {
  try {
    const response = await Api2Talk.get(`${PATH}/paginate?page=${page}&chatbot_id=${chatbotid}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getById = async (id) => {
  try {
    const response = await Api2Talk.get(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getJsonFlowById = async (id) => {
  try {
    const response = await Api2Talk.get(`${PATH}/get-json-flow/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const addJsonFlow = async (input) => {
  try {
    const response = await Api2Talk.put(`${PATH}/add-json-flow`, input);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const deleteById = async (id) => {
  try {
    const response = await Api2Talk.delete(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  addJsonFlow, create,
  deleteById,
  getAllPaginate,
  getById,
  getJsonFlowById, update
};

