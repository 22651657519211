import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'
import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import 'react-quill/dist/quill.snow.css'; // ES6
import { PapiroConsole } from '../../utils/papiroConsole'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SimpleSelect from '../../components/select/simple';
import * as IntegrationAuthService from "../../services/integrationauth.service";
import styled from 'styled-components';
import { Tabs, Tab, Table, TableCell, TableContainer, FormControlLabel } from '@mui/material';

import EditableTable from './components/editable-table'


import { RadioGroup, Radio } from '@mui/material';

import SimpleAutoComplete from '../../components/auto-complete/autocomplete-dialog-with-z-index'

const StyledTableContainer = styled(TableContainer)`
  width: 90%;
  margin: 20px auto;
`;

const StyledTable = styled(Table)`
  min-width: 650px;
  background-color: #ffffff;
`;

const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTableBodyCell = styled(TableCell)`
  && {
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;


const StyledIconButton = styled(IconButton)`
  && {
    color: ${props => props.delete ? '#f44336' : '#277afe'};
    &:hover {
      color: ${props => props.delete ? '#e53935' : '#277afe'};
    }
  }
`;



class AuthenticationRegistration extends Component {

  constructor(props) {
    super(props)
    const { userSession, organizations, intl } = this.props

    this.globalAdm = false
    this.isClient = true

    if (userSession.user.userrole == null) {
      Api.logoff()
    }



    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {

      name: '',
      url: '',
      basicAuthUser: '',
      basicAuthPassword: '',
      accessTokenFieldName: '',
      token : '',
      methodAuth: {id : 0, name:''},
      // dataType: null,
      //contentType: null,
      method: {id : 0, name:''},
      

    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      methodAuthList: [],
      // dataTypeList: [],
      //contentTypeList: [],
      methodList: [],
      tabIndex: 0,
      paramRows: [{ id: 1, name: '', value: '', isEditable: true }],
      headerRows: [{ id: 1, name: '', value: '', isEditable: true }],

      formDataRows: [{ id: 1, name: '', value: '', isEditable: true }],
      jsonValue: '',
      jsonError: '',
      requestBodyType: '1',

    }
    this.handleInputChange = this.handleInputChange.bind(this)
    //this.handleInputChange = this.handleInputChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  async componentDidMount() {

    await this.getData();

  }

  async getData() {
    this.setState({ loading: true })
    const result = await IntegrationAuthService.getData()
    if (result.success) {
      var data = result.data
      PapiroConsole.log("data here")
      PapiroConsole.log(data)
      this.setState({
        loading: false, methodAuthList: data.authenticationmethodintegration, methodList: data.methodintegration//, contentTypeList: data.contenttypeintegration //, dataTypeList: data.datatypeintegration, 

      })

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }

  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value



    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  validations(data) {
    PapiroConsole.log("entrei aqui")
    var returnStatus = true;
    const intl = this.props.intl;
    PapiroConsole.log("data")
    PapiroConsole.log(data)

    Object.keys(data).forEach(element => {
      PapiroConsole.log(element)
      if(element == 'name' || element == 'url' || element =='accessTokenFieldName' ){
        if (data[element] === null || data[element] == '' ) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento de todos os campos",
          })
  
          returnStatus = false;
        }
      }
      else if(element == 'methodAuth' || element == 'method'){

        if (data[element] === null || data[element].id == 0 ) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento de todos os campos",
          })
  
          returnStatus = false;
        }

      }
      
     

    });







    return returnStatus;


  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const formData = Object.assign({}, this.state.item);

    // const formData = ;

    

    var filteredListParams = []
    var filteredListHeaders = []
    var filteredListFormData = []

    if (this.state.paramRows) {
      filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
    }
    if (this.state.headerRows) {
      filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
    }

    if (this.state.formDataRows) {
      filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
    }

    formData.ParamRows = filteredListParams;
    formData.HeaderRows = filteredListHeaders;
    formData.BodyRequestRows = filteredListFormData;
    formData.RequestBodyType = this.state.requestBodyType;
    formData.jsonValue = this.state.jsonValue;

    


    if (this.validations(formData)) {

     

      formData.method = formData.method.id
      formData.methodAuth = formData.methodAuth.id

      PapiroConsole.log("formData here")
      PapiroConsole.log(formData)

      var result = await IntegrationAuthService.addItem(formData)
      if (result && result.success) {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "add.success" }),
          item: this.initialState,
          tabIndex: 0,
          paramRows: [
            { id: 1, name: '', value: '', isEditable: true }
          ],
          headerRows: [
            { id: 1, name: '', value: '', isEditable: true }
          ],
          formDataRows: [
            { id: 1, name: '', value: '', isEditable: true }
          ],
          jsonValue: '',
          jsonError: '',
          requestBodyType: '1',

        })


      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }

    }



  }

  closeNotification() {
    this.setState({ openNotification: false })
  }



  changeValues(stateName, value, text = '') {


    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  handleJsonValueChange = (e) => {
    const { value } = e.target;
    this.setState({ jsonValue: value });
    this.validateJson(value);
  };

  validateJson = (value) => {
    try {
      JSON.parse(value);
      this.setState({ jsonError: '' }); // Se o JSON for válido, limpa a mensagem de erro
    } catch (error) {
      this.setState({ jsonError: 'JSON inválido' }); // Se o JSON for inválido, define a mensagem de erro
    }
  };




  handleDeleteRow = (type, rowParam) => {
    this.setState(prevState => ({
      [type]: prevState[type].filter(row => row.id !== rowParam.id)
    }));
  };




  handleAddRow = (type, id) => {

    // const row = rows.find(row => row.id === id);
    // if (row.name && row.value) {
    //     setRows(prevState => prevState.map(row => (row.id === id ? { ...row, isEditable: false } : row)).concat({ id: Math.max(...prevState.map(r => r.id)) + 1, name: '', value: '', isEditable: true }));
    // } else {
    //     alert('Preencha todos os campos antes de adicionar.');
    // }

    PapiroConsole.log("entrei no handleRow 2 ")
    PapiroConsole.log(type)
    PapiroConsole.log(id)
    PapiroConsole.log(this.state[type])
    const row = this.state[type].find(row => row.id === id);
    if (row.name && row.value) {
      this.setState(prevState => ({
        [type]: prevState[type].map(row => (row.id === id ? { ...row, isEditable: false } : row)).concat({ id: Math.max(...prevState[type].map(r => r.id)) + 1, name: '', value: '', isEditable: true }),
      }));
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "'Preencha todos os campos antes de adicionar."
      })
    }
  };




  handleInputChangeTable = (type, field, value, id) => {

    PapiroConsole.log("type aqui")
    PapiroConsole.log(type)
    PapiroConsole.log(id)
    PapiroConsole.log(field)
    PapiroConsole.log(value)


    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));

  };

  handleTabChange = (e, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  handleRequestBodyTypeChange = (e) => {
    this.setState({ requestBodyType: e.target.value, jsonError: '' });
  };



  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    // const { rows } = this.state;
    const { tabIndex, paramRows, headerRows, requestBodyType, formDataRows, jsonValue, jsonError } = this.state;

    PapiroConsole.log("this.state")
    PapiroConsole.log(this.state)




    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#3f51b4' }} >
                <VpnKeyIcon />
              </Avatar>
              <Typography component='h1' variant='h5' style={{ marginTop: '10px' }}>
                {<FormattedMessage id="add.authentication" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>


                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })}  variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "authentication.method" })} options={this.state.methodAuthList} stateName='methodAuth' changeSelect={this.changeValues} selected={this.state.item.methodAuth} required />
                  </Grid>

                  {this.state.item.methodAuth && this.state.item.methodAuth.id == 2 &&

                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField inputProps={{ maxLength: 255 }} fullWidth label="Usuário da autenticação básica"  variant='outlined' name='basicAuthUser' onChange={this.handleInputChange} value={item.basicAuthUser} required ={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false}  />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField inputProps={{ maxLength: 255 }} fullWidth label="Senha da autenticação básica"  variant='outlined' name='basicAuthPassword' onChange={this.handleInputChange} value={item.basicAuthPassword} required ={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false} />
                      </Grid>

                    </>


                  }

                  {this.state.item.methodAuth && this.state.item.methodAuth.id == 1 &&

                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField  fullWidth label="Token"  variant='outlined' name='token' onChange={this.handleInputChange} value={item.token} required = {this.state.item.methodAuth && this.state.item.methodAuth.id == 1 ? true : false} />
                      </Grid>
                    </>

                  }



                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "url" })} variant='outlined' name='url' onChange={this.handleInputChange} value={item.url} required selected={this.state.item.url} />
                  </Grid>
                  {/* 
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect label={intl.formatMessage({ id: "data.types" })} options={this.state.dataTypeList} stateName='dataType' changeSelect={this.changeValues} selected={this.state.item.dataType} required />
                  </Grid> */}

               {/*   <Grid item xs={12} sm={12}>
                    <SimpleSelect label={intl.formatMessage({ id: "content.type" })} options={this.state.contentTypeList} stateName='contentType' changeSelect={this.changeValues} selected={this.state.item.contentType} required />
                  </Grid>*/}

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "call.method" })} options={this.state.methodList} stateName='method' changeSelect={this.changeValues} selected={this.state.item.method} required />
                  </Grid>


                  <Grid item xs={12} sm={12}>
                    <Tabs style={{ marginTop: '10px' }} value={tabIndex} onChange={this.handleTabChange}>
                      <Tab label="Parâmetros de consulta" />
                      <Tab label="Cabeçalho" />
                      <Tab label="Corpo da Requisição" />
                    </Tabs>

                    {tabIndex === 0 && (
                      <EditableTable rows={paramRows} handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="paramRows" />
                    )}

                    {tabIndex === 1 && (
                      <EditableTable rows={headerRows} handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="headerRows" />
                    )}

                    {tabIndex === 2 && (

                      <Grid item xs={12}>
                        <RadioGroup
                          value={requestBodyType}
                          onChange={this.handleRequestBodyTypeChange}
                          sx={{ display: 'flex', flexDirection: 'row' }} // Exibir horizontalmente
                        >
                          <FormControlLabel value="1" control={<Radio />} label="Nenhum" />
                          <FormControlLabel value="2" control={<Radio />} label="Form-data" />
                          <FormControlLabel value="3" control={<Radio />} label="JSON" />
                        </RadioGroup>




                        {requestBodyType === '2' && (
                          <EditableTable handleDeleteRow={this.handleDeleteRow} handleAddRow={this.handleAddRow} rows={formDataRows} handleInputChangeTable={this.handleInputChangeTable} type="formDataRows" />
                        )}

                        {requestBodyType === '3' && (
                          <div>
                            <StyledTextField
                              multiline
                              rows={10}
                              value={jsonValue}
                              onChange={this.handleJsonValueChange}
                              placeholder="Digite seu JSON aqui"
                              error={!!jsonError} // Define o estado de erro
                              helperText={jsonError} // Exibe a mensagem de erro
                            />
                          </div>
                        )}

                      </Grid>

                    )}
                  </Grid>


                  {/*<StyledTableContainer component={Paper}>
                    <StyledTable>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nome</StyledTableCell>
                          <StyledTableCell>Valor</StyledTableCell>
                          <StyledTableCell>Ação</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(row => (
                          <TableRow key={row.id}>
                            <StyledTableBodyCell>
                              <StyledTextField
                                value={row.name}
                                onChange={(e) => this.handleInputChangeTable(e, row.id, 'name')}
                                disabled={!row.isEditable}
                              />
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                              <StyledTextField
                                value={row.value}
                                onChange={(e) => this.handleInputChangeTable(e, row.id, 'value')}
                                disabled={!row.isEditable}
                              />
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                              {row.isEditable ? (
                                <StyledIconButton onClick={() => this.handleAddRow(row.id)}>
                                  <SaveIcon />
                                </StyledIconButton>
                              ) : (
                                <StyledIconButton delete onClick={() => this.handleDeleteRow(row.id)}>
                                  <Delete />
                                </StyledIconButton>
                              )}
                            </StyledTableBodyCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </StyledTableContainer>*/}


                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "access.token.field.name" })}  variant='outlined' name='accessTokenFieldName' onChange={this.handleInputChange} value={item.accessTokenFieldName} required />
                  </Grid>



                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            {
              this.state.loading && (
                <Overlay>
                  <CircularProgress color='secondary' />
                </Overlay>
              )
            }
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />



          </Container >
        </main >
        <Footer />
      </div >
    )
  }
}

AuthenticationRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AuthenticationRegistration)));