import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SharedEvents } from '../../../joint-plus/controller';
import { actionCreator } from '../../../redux/helpers/actionCreator';

interface Props {
    className?: string;
    type?: string;
    placeholder?: string;
    spellCheck?: boolean;
    value?: string | number;
    defaultValue?: string | number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean,
    options: {id:number, name:string}[]
}

const BATCH_NAME = 'inspector-input';

const Select = (props: Props): ReactElement => {

    const dispatch = useDispatch();

    const onFocus = (): void => {
        dispatch(
            actionCreator(SharedEvents.GRAPH_START_BATCH, BATCH_NAME)
        );
    };

    const onBlur = (): void => {
        dispatch(
            actionCreator(SharedEvents.GRAPH_STOP_BATCH, BATCH_NAME)
        );
    };

    useEffect(() => {
        return () => {
            onBlur();
        }
    }, []);

    return (
        <select
          className={props.className}
          placeholder={props.placeholder}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={(event) => props.onChange(event as any)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={props.disabled}
        >
          <option value="">Selecione</option>
          {props?.options && props?.options?.map(option => (
            <option value={option.id}>{option.name}</option>
          ))}
        </select>
    );
};

export default Select;
