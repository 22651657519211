import { Button, CircularProgress, Grid } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/channels.js';
import Api from "../../../../services/api";
import Api2Talk from "../../../../services/api2talk";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';



class ChannelChatbot extends Component {


  constructor(props) {
    super(props);

    const { userSession, channels } = this.props;

    this.globalAdm = false;
    this.isClient = true;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!channels || (channels && !channels.channel)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/channels`
    }

    this.itemInitialState = {
      channelid: channels.channel.id,
      chatbotid: null
    };

    this.state = {
      item: this.itemInitialState,
      optionsChatbots: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
    };

    this.closeNotification = this.closeNotification.bind(this);
  }

  requestNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  requestThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }


  notificationMessage(message) {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: 'error',
      notificationMessage: message
    });
  }

  validateFieldsRequired() {
    const intl = this.props.intl;

    if (!this.state.item.chatbotid?.id) {
      const message = intl.formatMessage({ id: "channel.missing.chatbot" });
      this.notificationMessage(message);
      return false;
    }

    return true;
  }


  handleSubmit = async e => {
    e.preventDefault();

    if (!this.validateFieldsRequired()) return;

    let input = { channelid: this.state.item.channelid, chatbotid: this.state.item.chatbotid?.id };
    
    Api2Talk.put(`/channel/add-chatbot`, input)
      .then(result => {
        const intl = this.props.intl;
        this.props.setLoadingState(false)

        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
          });
        } else {
          this.props.setLoadingState(false)
          this.requestNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.props.setLoadingState(false)
        this.requestThrowError(err);
        Api.kickoff(err);
      });
  }

  fetchOptionsChatbots = async () => {
    this.props.setLoadingState(true);
    try {
      const result = await Api2Talk.get(`/chatbot/actives`);

      if (result.data.success) {
        const chatbotCollection = result.data.data;
        this.setState({ optionsChatbots: chatbotCollection })


        const resultGetChatbot = await Api2Talk.get(`/channel/get-chatbot-by-channel/${this.state.item?.channelid}`);

        if (resultGetChatbot.data.success) {
          if (resultGetChatbot.data.data.chatbotid) {
            const item = chatbotCollection.find(item => item.id === resultGetChatbot.data.data.chatbotid);

            if (item) this.setState({ item: {...this.state.item, chatbotid: item} })
          }
        }

      } else {
        this.requestNotSuccess(result);
        Api.kickoff(result);
      }
    } catch (err) {
      this.requestThrowError(err);
      Api.kickoff(err);
    } finally {
      this.props.setLoadingState(false);
    }
  };

  handleChangeAutoComplete(stateName, value) {
    let data = value.id > 0 ? value : null;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));

    if (stateName == "MessageProviderId" && value.id > 0) {
      this.props.setMessageProviderIdCurrentChannel(value.id ?? null);
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async componentDidMount() {
    await this.fetchOptionsChatbots();
  }

  render() {
    const { classes } = this.props;

    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
        <div>
          <form name='myForm' className={classes.form}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="Chatbot"
                  label="Chatbot"
                  options={this.state.optionsChatbots}
                  stateName='chatbotid'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.item.chatbotid}
                  htmlOptions={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </form>
        </div>


        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        {
          this.state.openNotification && (
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          )
        }

      </div>
    )
  }
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  channels: state.channels
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChannelChatbot)));

ChannelChatbot.contextType = AbilityContext;