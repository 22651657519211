import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Container = styled.div`
 
  margin-top: 20px ;
  margin-bottom: 20px ;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Status = styled.h2`
  color: #687EFC; /* Verde para sucesso */
  margin-bottom: 10px;
`;

const Content = styled.pre`
  background: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto; /* Barra de rolagem vertical se necessário */
  white-space: pre-wrap; /* Mantém espaços em branco e quebras de linha */
  word-wrap: break-word; /* Quebra palavras longas */
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Alinha o botão à direita */
  margin-top: 10px; /* Margem para separar do conteúdo */
`;

const CopyButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  background: #687EFC;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

const ApiResponseDisplay = ({ status, content }) => {

    const handleCopy = () => {
        navigator.clipboard.writeText(content).then(() => {
            
        }).catch(err => {

        });
    };
    return (
        <Container>
            <Status>{status}</Status>
            <Content>{content}</Content>
            <ButtonContainer>
                <IconButton onClick={handleCopy} aria-label="Copiar Resposta">
                    <ContentCopyIcon sx={{ color: '#687EFC' }} />
                </IconButton>
            </ButtonContainer>
        </Container>
    );
};

export default ApiResponseDisplay;