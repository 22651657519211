import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import Select from '../Input/Select';
import { useBaseInspector } from './useBaseInspector';

import * as ParamsService from "../../../../../../../../services/2talk/chatbotResponseParams.service";

interface Props {
    cell: shapes.app.Message;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    integrationParamsId: ['attrs', 'integrationParamsId', 'id']
};

const ResponseParamInspector = (props: Props): ReactElement => {

    const { cell } = props;

    const [paramsOptions, setParamsOptions] = useState([]);

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [integrationParamsId, setIntegrationParamsId] = useState(null);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setIntegrationParamsId(cell.prop(cellProps.integrationParamsId))
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    const fetchParams = async () => {
        const params = await ParamsService.getAll();
        setParamsOptions(params?.data ?? []);
    }

    useEffect(() => {
        fetchParams();
    }, [])

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <label>Descrição
                <Input type="text"
                       placeholder="Enter description"
                       value={description}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                />
            </label>

            <label>Parâmetro
                <Select
                    type="text"
                    value={integrationParamsId}
                    onChange={(e) => changeCellProp(cellProps.integrationParamsId, e.target.value)}
                    options={paramsOptions}
                />
            </label>
        </>
    );
};

export default ResponseParamInspector;
