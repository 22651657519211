import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MaterialTable from 'material-table'
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import * as CalledService from '../../services/called.service'
import fileDownload from 'js-file-download'
import * as OrganizationFieldService from '../../services/organizationfield.service'
import * as calledsActions from '../../redux/actions/calleds'
import * as TeamsService from '../../services/techinicalteam.service'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import { saveAs } from 'file-saver'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple'
import SimpleAutoCompleteMultipleCustomField from '../../components/auto-complete/autocomplete-multiple-custom-field'
import SimpleAutoCompleteMultipleCustomFieldPersonalized from '../../components/auto-complete/autocomplete-multiple-custom-field-personalized'
import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import autoTable from 'jspdf-autotable'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import html2canvas from 'html2canvas'
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CsvDownloadButton from 'react-json-to-csv'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from "../../utils/papiroConsole"

import ApiReports from "../../services/apireport";
import * as Service from '../../services/techinicalteamorganizationcategory.service';
import { getAllSelectionModelWithColorHex } from "../../services/status.service";
import SimpleCheckBox from "../../components/checkbox/check"

class CalledsOrganizationsReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession } = this.props

    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      OrganizationFilter: 0,
      TechinicalTeamFilter: { id: 0, name: '' },
      location: 0,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      organizationList: [],
      organizationAddressesList: [],
      exportList: [],
      exportColumns: [],
      showDownloadButton: false,
      calledPdfList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      showHeader: false,
      strhtmlModalOpen: false,
      dialogHTMLString: '',
      OrgsList: [],
      TechinicalTeamList: [],
      customFieldId: [],
      CustomFields: [],
      statusList: [],
      statusIds: [],
      CustomFieldNames: [],
      columns: [],
      columnsExcell: [],
      allOrganizations : false,
      allCustomFields : false,
    }
  }

  openHTMLRenderModal = (htmlString) => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: htmlString })
  }

  closeHTMLRenderModal = () => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: '' })
  }

  async getTechinicalTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await Service.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await Service.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getAllStatus() {
    let result = await getAllSelectionModelWithColorHex();
    if (result && result.success) {
        if (result.data != null)
            this.setState({ statusList: result.data })
    } else {
        const intl = this.props.intl
        this.setState({
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
    }
}

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })
    const intl = this.props.intl;

    if (stateName === 'OrganizationFilter') {
      this.setState({ [stateName]: value.id });
      if (value.id > 0) {
        await this.getTechinicalTeams(value.id)
        await this.getCustomFieldsByOrg(value.id)
        if (!this.state.TechinicalTeamList.find(t => t.id == this.state.TechinicalTeamFilter.id)) {
          this.setState({ TechinicalTeamFilter: { id: 0, name: '' } })
        }
      } else {
        this.setState({ TechinicalTeamFilter: { id: 0, name: '' }, TechinicalTeamList: [], customFieldId: [] })
        await this.getTechinicalTeams()
      }
    }
    else if (stateName === 'customFieldId') {
      // if (value.length == 20) {
      //   this.setState({
      //     loading: false, openNotification: true, notificationVariant: "error",
      //     notificationMessage: intl.formatMessage({ id: "maximum.choices.custom.field" })
      //   });

      // }
      this.setState({ [stateName]: value });

    }
    else if (stateName === 'allOrganizations'){
      if(value == false){

            if(this.state.OrganizationFilter != null && this.state.OrganizationFilter != 0){
              await this.getCustomFieldsByOrg(this.state.OrganizationFilter)
            }
            
      }
      else{
       await this.getAllCustomFields()

      }
      this.setState({ [stateName]: value });
    }
    


    else {
      PapiroConsole.log(value)
      PapiroConsole.log("value")
      this.setState({ [stateName]: value });
    }







    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  async getCustomFieldsByOrg(organizationId) {
    this.setState({ loading: true });

    const result = await OrganizationFieldService.getAllActiveByOrganizationIdWithoutIncludes(organizationId);

    if (result.success) {
      PapiroConsole.log(result)
      PapiroConsole.log("result custom aqui")

      this.setState({ loading: false, CustomFields: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getAllCustomFields() {
    this.setState({ loading: true });

    const result = await OrganizationFieldService.getAllActivedWithOrganizations();

    if (result.success) {
      PapiroConsole.log(result)
      PapiroConsole.log("result custom aqui")

      this.setState({ loading: false, CustomFields: result.data });
      PapiroConsole.log("result.data campos personalizados" )
      PapiroConsole.log(result.data )
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  setPagination = (count, page) => {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10)
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({ pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }



  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch = _ => {
    this.getCalleds('', this.state.page)
  }

  getOrganizations = async _ => {
    let data = [];
    const { userSession } = this.props
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    }
    this.setState({ OrgsList: data })
  }

  deleteItem = (data, resolve) => {
    const intl = this.props.intl;
    let abl = this.context.can('Delete', 'Called')
    if (!abl) {
      this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({ id: "operation.notAllowed" }) })
      resolve()
      return false
    }
    Api.delete(`/logmail/${data.id}`)
      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "called.list.notification.delete.success" }),
            resultList: []
          })

          this.getCalleds()
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
        resolve()
      })
      .catch(err => {
        resolve()
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getOrganizations()
    await this.getTechinicalTeams()
    await this.getAllStatus()

    this.setState({ loading: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "techinicalteamsreport")
      this.getCalleds(this.props.location.state)
    else if (nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if (nextProps.location.state === "reload")
      this.componentDidMount()
  }

  getFileNew = async () => {
    this.setState({ loading: true })

    // let list = this.state.customFieldId;
 

    var list
    //checando se a opção de selecionar todos os campos personalizados está marcada
    if(this.state.allCustomFields == true){
      list = this.state.CustomFields;
    }
    else{
      list = this.state.customFieldId;
    }
    PapiroConsole.log(list)
    PapiroConsole.log("list aqui")


    var customfieldids = "";
    var customfieldNames = []
    var customfieldnamestoback = ""

    if (list != null && list != "") {


      for (let value in list) {
        customfieldids = list[value].id + "," + customfieldids;
        customfieldNames.unshift(list[value].name)
        customfieldnamestoback = list[value].name + "ƒ" + customfieldnamestoback;

      }
      if (customfieldids.length > 0) {
        customfieldids = customfieldids.substr(0, customfieldids.length - 1);
        customfieldnamestoback = customfieldnamestoback.substr(0, customfieldnamestoback.length - 1);
      }

      var status = ""
      if (this.state.statusIds != null && this.state.statusIds.length > 0) {
        let ids = this.state.statusIds.map(c => c.id);
        status = ids.join(',');
      }


      PapiroConsole.log(customfieldids)
      PapiroConsole.log("customfieldids")
      PapiroConsole.log(customfieldnamestoback)
      PapiroConsole.log("customfieldnamestoback")

    }

    let data =  {
      OrganizationId: this.state.OrganizationFilter,
      TechinicalTeamId: this.state.TechinicalTeamFilter.id,
      DateStart : moment(this.state.dateStart).format('YYYY-MM-DD'),
      DateEnd : moment(this.state.dateEnd).format('YYYY-MM-DD'),
      CustomFieldIds : customfieldids,
      customfieldnamestoback : customfieldnamestoback ,
      StatusIds : status,
      AllOrganizationsChecked : this.state.allOrganizations
      
    }


  let url = `/report/calledsorganizations/excell/new`
    //let url = `/report/calledsorganizations/excell?org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&customfieldids=${customfieldids}&customfieldnamestoback=${customfieldnamestoback}&status=${status}`

    ApiReports.post(url, data)
      .then((response) => {
        PapiroConsole.log(response)
        PapiroConsole.log("response")
        if (response.data.success) {
          PapiroConsole.log("success")
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({ id: "report.sent" }) + " (" + this.props.userSession.user.email + ")",
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response.data.errors && response.data.errors[0] ? response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: error.data && error.data.response && error.data.response.data && error.data.response.data.errors && error.data.response.data.errors[0] ? error.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      });

  }

  getFile = async () => {
    this.setState({ loading: true })

    let list = this.state.customFieldId;
    PapiroConsole.log(list)
    PapiroConsole.log("list aqui")

    this.setState({
      exportList: [], showDownloadButton: false
    })
    var customfieldids = "";
    var customfieldNames = []

    if (list != null && list != "") {


      for (let value in list) {
        customfieldids = list[value].id + "," + customfieldids;
        customfieldNames.unshift(list[value].name)

      }
      if (customfieldids.length > 0) {
        customfieldids = customfieldids.substr(0, customfieldids.length - 1);
      }


      PapiroConsole.log(customfieldids)
      PapiroConsole.log("customfieldids")

    }



    let url = `/calleds/getexcell?org=${this.state.OrganizationFilter}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&customfieldids=${customfieldids}`

    Api.get(url)
      .then(async (result) => {
        PapiroConsole.log(result)
        PapiroConsole.log("result")


        const formattedList = result.data.data;


        var cols = await this.getColumnsExcell(formattedList, customfieldNames);

        var formattedCols = cols.map(item => item.header);

        this.setState({
          exportList: formattedList, showDownloadButton: true, exportColumns: formattedCols,
        })
        /*
                const wb = new ExcelJS.Workbook()
                const ws = wb.addWorksheet()
                await this.getColumnsExcell(formattedList, customfieldNames)
                ws.columns = this.state.columnsExcell
                ws.getCell('A1').style = { font: { bold: true } }
                ws.getCell('B1').style = { font: { bold: true } }
                ws.getCell('C1').style = { font: { bold: true } }
                ws.getCell('D1').style = { font: { bold: true } }
                ws.getCell('E1').style = { font: { bold: true } }
                ws.getCell('F1').style = { font: { bold: true } }
                ws.getCell('G1').style = { font: { bold: true } }
                ws.getCell('H1').style = { font: { bold: true } }
                ws.getCell('I1').style = { font: { bold: true } }
                ws.getCell('J1').style = { font: { bold: true } }
                ws.getCell('K1').style = { font: { bold: true } }
                ws.getCell('L1').style = { font: { bold: true } }
                ws.getCell('M1').style = { font: { bold: true } }
                ws.getCell('N1').style = { font: { bold: true } }
                ws.getCell('O1').style = { font: { bold: true } }
                ws.getCell('P1').style = { font: { bold: true } }
                ws.getCell('Q1').style = { font: { bold: true } }
                ws.getCell('R1').style = { font: { bold: true } }
                ws.getCell('S1').style = { font: { bold: true } }
                ws.getCell('T1').style = { font: { bold: true } }
                ws.getCell('U1').style = { font: { bold: true } }
                ws.getCell('V1').style = { font: { bold: true } }
                ws.getCell('W1').style = { font: { bold: true } }
                ws.getCell('X1').style = { font: { bold: true } }
                ws.getCell('Y1').style = { font: { bold: true } }
                ws.getCell('Z1').style = { font: { bold: true } }
                ws.addRows(formattedList);
                const buf = await wb.xlsx.writeBuffer()
                saveAs(new Blob([buf]), 'relatorio_chamados_organizacao.xlsx')
          	
        */
        // Initiate Excel menjadi Workbook Writer
        /*
        const options = {
          filename: `./${Date.now()}-relatorio_chamados_organizacao.xlsx`,
          useStyles: true,
          useSharedStrings: true,
          //stream: res,
          };		
      	
          const workBook = new ExcelJS.stream.xlsx.WorkbookWriter(options);
        let defaultsheetname = "data";
        const workSheet = workBook.addWorksheet(defaultsheetname);
        await this.getColumnsExcell(formattedList, customfieldNames)
            workSheet.columns = this.state.columnsExcell;
            workSheet.addRows(formattedList).commit();
        await workBook.commit();
    
      	
            let filename=`./${Date.now()}-relatorio_chamados_organizacao.xlsx`;
        alert(filename);  
        const stream = fs.createReadStream(filename);
      	
        //const workbook = new ExcelJS.Workbook();
        //const streamWorkBook = await workbook.xlsx.read(stream);
        //const sheet = streamWorkBook.getWorksheet("Sheet1");
        //await this.getColumnsExcell(formattedList, customfieldNames);
            //sheet.columns = this.state.columnsExcell;
        //sheet.addRows(formattedList).commit();
        //await workbook.commit();
      */

        this.setState({ loading: false })
      })
      .catch(err => {

        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }

  getMessageBadUseFlag = message => {
    const intl = this.props.intl;
    let writing = ""
    if (message === true)
      writing = intl.formatMessage({ id: "yes" })
    else if (message === false)
      writing = intl.formatMessage({ id: "no" })
    else
      writing = intl.formatMessage({ id: "undefined" })

    return writing
  }

  getColumns(data, customfieldNames) {

    PapiroConsole.log("entrei no get columns")
    PapiroConsole.log(data)
    const intl = this.props.intl;
    var columns =
      [

        { title: intl.formatMessage({ id: "id" }), field: 'id' },
        { title: intl.formatMessage({ id: "title" }), field: 'title' },
        { title: intl.formatMessage({ id: "origin.request" }), field: 'origem' },

        { title: intl.formatMessage({ id: "category" }), field: 'categoria' },
        { title: intl.formatMessage({ id: "organization" }), field: 'organizacao' },

        { title: intl.formatMessage({ id: "menu.statuses" }), field: 'status' },
        { title: intl.formatMessage({ id: "requesting.user.name" }), field: 'requesting_user_name' },
        { title: intl.formatMessage({ id: "requesting.user.document" }), field: 'requesting_user_document' },
        { title: intl.formatMessage({ id: "requesting.user.email" }), field: 'requesting_user_email' },
        { title: intl.formatMessage({ id: "unit" }), field: 'unity' },
        { title: intl.formatMessage({ id: "open.date" }), field: 'data_abertura' },
        { title: intl.formatMessage({ id: "begin_attendance" }), field: 'data_inicio_atendimento' },
        { title: intl.formatMessage({ id: "day" }), field: 'dia' },
        { title: intl.formatMessage({ id: "month" }), field: 'mes' },
        { title: intl.formatMessage({ id: "year" }), field: 'ano' },
        { title: intl.formatMessage({ id: "total.minutes" }), field: 'total_minutes' },
        { title: intl.formatMessage({ id: "solution.date" }), field: 'data_solucao' },
        { title: intl.formatMessage({ id: "team" }), field: 'time' },
        { title: intl.formatMessage({ id: "assigned" }), field: 'responsavel' },
        { title: intl.formatMessage({ id: "service.time" }), field: 'tempo_atendimento' },
        { title: intl.formatMessage({ id: "total.time" }), field: 'total_time' },
        { title: intl.formatMessage({ id: "interactive.time" }), field: 'total_interaction_time_without_workhour' },
        { title: intl.formatMessage({ id: "interactive.time.sla" }), field: 'total_interaction_time' },
        { title: intl.formatMessage({ id: "calledclosingstatus" }), field: 'called_closing_status' },
        { title: intl.formatMessage({ id: "provider.name" }), field: 'provider' },







      ];

    var i = 1;


    if (customfieldNames.length > 0) {
      customfieldNames.forEach(element => {

        columns.push({ title: element, field: `campo_personalizado${i}` })
        i++

      });
    }

    /*if(data.length > 0){
      data.forEach(element => {
          if(element.campo_personalizado1){
            PapiroConsole.log("entrei aqui no if campo personalizado 1")
            if (columns.filter(e => e.title === 'campo_personalizado1').length == 0) {
              PapiroConsole.log("entrei de novo no if campo personalizado 1")
              columns.push({ title: "campo_personalizado1", field: 'campo_personalizado1' })
            }
          }
         
           if(element.campo_personalizado2){
            PapiroConsole.log("entrei aqui no if campo personalizado 2")
            if (columns.filter(e => e.title === 'campo_personalizado2').length == 0) {
              PapiroConsole.log("entrei de novo no if campo personalizado 2")
              columns.push({ title: "campo_personalizado2", field: 'campo_personalizado2' })
            }
          }
           if(element.campo_personalizado3){
            if (columns.filter(e => e.title === 'campo_personalizado3').length == 0) {
              columns.push({ title: "campo_personalizado3", field: 'campo_personalizado3' })
            }
          }
           if(element.campo_personalizado4){
            if (columns.filter(e => e.title === 'campo_personalizado4').length == 0) {
              columns.push({ title: "campo_personalizado4", field: 'campo_personalizado4' })
            }
          }
           if(element.campo_personalizado5){
            if (columns.filter(e => e.title === 'campo_personalizado5').length == 0) {
              columns.push({ title: "campo_personalizado5", field: 'campo_personalizado5' })
            }
          }
           if(element.campo_personalizado6){
            if (columns.filter(e => e.title === 'campo_personalizado6').length == 0) {
              columns.push({ title: "campo_personalizado6", field: 'campo_personalizado6' })
            }
          }
           if(element.campo_personalizado7){
            if (columns.filter(e => e.title === 'campo_personalizado7').length == 0) {
              columns.push({ title: "campo_personalizado7", field: 'campo_personalizado7' })
            }
          }
           if(element.campo_personalizado8){
            if (columns.filter(e => e.title === 'campo_personalizado8').length == 0) {
              columns.push({ title: "campo_personalizado8", field: 'campo_personalizado8' })
            }
          }
           if(element.campo_personalizado9){
            if (columns.filter(e => e.title === 'campo_personalizado9').length == 0) {
              columns.push({ title: "campo_personalizado9", field: 'campo_personalizado9' })
            }
          }
           if(element.campo_personalizado10){
            if (columns.filter(e => e.title === 'campo_personalizado10').length == 0) {
              columns.push({ title: "campo_personalizado10", field: 'campo_personalizado10' })
            }
          }
           if(element.campo_personalizado11){
            if (columns.filter(e => e.title === 'campo_personalizado11').length == 0) {
              columns.push({ title: "campo_personalizado11", field: 'campo_personalizado11' })
            }
          }
           if(element.campo_personalizado12){
            if (columns.filter(e => e.title === 'campo_personalizado12').length == 0) {
              columns.push({ title: "campo_personalizado12", field: 'campo_personalizado12' })
            }
          }
           if(element.campo_personalizado13){
            if (columns.filter(e => e.title === 'campo_personalizado13').length == 0) {
              columns.push({ title: "campo_personalizado13", field: 'campo_personalizado13' })
            }
          }
           if(element.campo_personalizado14){
            if (columns.filter(e => e.title === 'campo_personalizado14').length == 0) {
              columns.push({ title: "campo_personalizado14", field: 'campo_personalizado14' })
            }
          }
           if(element.campo_personalizado15){
            if (columns.filter(e => e.title === 'campo_personalizado15').length == 0) {
              columns.push({ title: "campo_personalizado15", field: 'campo_personalizado15' })
            }
          }
           if(element.campo_personalizado16){
            if (columns.filter(e => e.title === 'campo_personalizado16').length == 0) {
              columns.push({ title: "campo_personalizado16", field: 'campo_personalizado16' })
            }
          }
           if(element.campo_personalizado17){
            if (columns.filter(e => e.title === 'campo_personalizado17').length == 0) {
              columns.push({ title: "campo_personalizado17", field: 'campo_personalizado17' })
            }
          }
           if(element.campo_personalizado18){
            if (columns.filter(e => e.title === 'campo_personalizado18').length == 0) {
              columns.push({ title: "campo_personalizado18", field: 'campo_personalizado18' })
            }
          }
           if(element.campo_personalizado19){
            if (columns.filter(e => e.title === 'campo_personalizado19').length == 0) {
              columns.push({ title: "campo_personalizado19", field: 'campo_personalizado19' })
            }
          }
           if(element.campo_personalizado20){
            if (columns.filter(e => e.title === 'campo_personalizado20').length == 0) {
              columns.push({ title: "campo_personalizado20", field: 'campo_personalizado20' })
            }
          }


          
      });
    }*/

    this.setState({ columns: columns })

  }


  async getColumnsExcell(data, customfieldNames) {

    PapiroConsole.log("entrei no get columns")
    PapiroConsole.log(data)
    const intl = this.props.intl;

    /*
            [Column("id")]
            [Column("created_at_date")]
            [Column("data_abertura")]
            [Column("dia")]
            [Column("mes")]
            [Column("ano")]
            [Column("status")]
            [Column("created_at")]
            [Column("data_solucao")]
            [Column("data_solucao_date")]
            [Column("data_inicio_atendimento_date")]
            [Column("tempo_atendimento")]
            [Column("total_time")]
            [Column("total_minutes")]
            [Column("time")]
            [Column("responsavel")]
            [Column("categoria")]
            [Column("origem")]
            [Column("organizacao")]
            [Column("data_inicio_atendimento")]
            [Column("requesting_user_document")]
            [Column("requesting_user_name")]
            [Column("requesting_user_email")]
            [Column("title")]
            [Column("description")]
    
    */

    var columns =
      [
        { header: intl.formatMessage({ id: "id" }), key: 'id', width: 25 },
        { header: intl.formatMessage({ id: "open.date" }), key: 'data_abertura', width: 25 },
        { header: intl.formatMessage({ id: "day" }), key: 'dia', width: 25 },
        { header: intl.formatMessage({ id: "month" }), key: 'mes', width: 25 },
        { header: intl.formatMessage({ id: "year" }), key: 'ano', width: 25 },
        { header: intl.formatMessage({ id: "menu.statuses" }), key: 'status', width: 25 },
        { header: intl.formatMessage({ id: "solution.date" }), key: 'data_solucao', width: 25 },
        { header: intl.formatMessage({ id: "service.time" }), key: 'tempo_atendimento', width: 25 },
        { header: intl.formatMessage({ id: "total.time" }), key: 'total_time', width: 25 },
        { header: intl.formatMessage({ id: "total.minutes" }), key: 'total_minutes', width: 25 },
        { header: intl.formatMessage({ id: "team" }), key: 'time', width: 25 },
        { header: intl.formatMessage({ id: "assigned" }), key: 'responsavel', width: 25 },
        { header: intl.formatMessage({ id: "category" }), key: 'categoria', width: 25 },
        { header: intl.formatMessage({ id: "origin.request" }), key: 'origem', width: 25 },
        { header: intl.formatMessage({ id: "organization" }), key: 'organizacao', width: 25 },
        { header: intl.formatMessage({ id: "begin_attendance" }), key: 'data_inicio_atendimento', width: 25 },
        { header: intl.formatMessage({ id: "requesting.user.document" }), key: 'requesting_user_document', width: 25 },
        { header: intl.formatMessage({ id: "requesting.user.name" }), key: 'requesting_user_name', width: 25 },
        { header: intl.formatMessage({ id: "requesting.user.email" }), key: 'requesting_user_email', width: 25 },
        { header: intl.formatMessage({ id: "title" }), key: 'title', width: 25 },
        { header: intl.formatMessage({ id: "description" }), key: 'description', width: 25 },
      ];



    var i = 1;

    if (customfieldNames.length > 0) {
      customfieldNames.forEach(element => {

        columns.push({ header: element, key: `campo_personalizado${i}`, width: 25 })
        i++

      });
    }



    /*if(data.length > 0){
      data.forEach(element => {
          if(element.campo_personalizado1){
            PapiroConsole.log("entrei aqui no if")
            if (columns.filter(e => e.header === 'campo_personalizado1').length == 0) {
              PapiroConsole.log("entrei aqui no if 2")
              columns.push({ header: "campo_personalizado1", key: 'campo_personalizado1', width: 25 })
            }
          }
         
           if(element.campo_personalizado2){
            if (columns.filter(e => e.header === 'campo_personalizado2').length == 0) {
              
              columns.push({ header: "campo_personalizado2", key: 'campo_personalizado2', width: 25 })
            }
          }
           if(element.campo_personalizado3){
            if (columns.filter(e => e.header === 'campo_personalizado3').length == 0) {
              
              columns.push({ header: "campo_personalizado3", key: 'campo_personalizado3', width: 25 })
            }
          }
           if(element.campo_personalizado4){
            if (columns.filter(e => e.header === 'campo_personalizado2').length == 0) {
              
              columns.push({ header: "campo_personalizado4", key: 'campo_personalizado4', width: 25 })
            }
          }
           if(element.campo_personalizado5){
            if (columns.filter(e => e.header === 'campo_personalizado5').length == 0) {
              
              columns.push({ header: "campo_personalizado5", key: 'campo_personalizado5', width: 25 })
            }
          }
           if(element.campo_personalizado6){
            if (columns.filter(e => e.header === 'campo_personalizado6').length == 0) {
              
              columns.push({ header: "campo_personalizado6", key: 'campo_personalizado6', width: 25 })
            }
          }
           if(element.campo_personalizado7){
            if (columns.filter(e => e.header === 'campo_personalizado7').length == 0) {
              
              columns.push({ header: "campo_personalizado7", key: 'campo_personalizado7', width: 25 })
            }
          }
           if(element.campo_personalizado8){
            if (columns.filter(e => e.header === 'campo_personalizado8').length == 0) {
              
              columns.push({ header: "campo_personalizado8", key: 'campo_personalizado8', width: 25 })
            }
          }
           if(element.campo_personalizado9){
            if (columns.filter(e => e.header === 'campo_personalizado9').length == 0) {
              
              columns.push({ header: "campo_personalizado9", key: 'campo_personalizado9', width: 25 })
            }
          }
           if(element.campo_personalizado10){
            if (columns.filter(e => e.header === 'campo_personalizado10').length == 0) {
              
              columns.push({ header: "campo_personalizado10", key: 'campo_personalizado10', width: 25 })
            }
          }
          if(element.campo_personalizado11){
            if (columns.filter(e => e.header === 'campo_personalizado11').length == 0) {
              
              columns.push({ header: "campo_personalizado11", key: 'campo_personalizado11', width: 25 })
            }
          }
           if(element.campo_personalizado12){
            if (columns.filter(e => e.header === 'campo_personalizado12').length == 0) {
              
              columns.push({ header: "campo_personalizado12", key: 'campo_personalizado12', width: 25 })
            }
          }
           if(element.campo_personalizado13){
            if (columns.filter(e => e.header === 'campo_personalizado13').length == 0) {
              
              columns.push({ header: "campo_personalizado13", key: 'campo_personalizado13', width: 25 })
            }
          }
           if(element.campo_personalizado14){
            if (columns.filter(e => e.header === 'campo_personalizado14').length == 0) {
              
              columns.push({ header: "campo_personalizado14", key: 'campo_personalizado14', width: 25 })
            }
          }
           if(element.campo_personalizado15){
            if (columns.filter(e => e.header === 'campo_personalizado15').length == 0) {
              
              columns.push({ header: "campo_personalizado15", key: 'campo_personalizado15', width: 25 })
            }
          }
           if(element.campo_personalizado16){
            if (columns.filter(e => e.header === 'campo_personalizado16').length == 0) {
              
              columns.push({ header: "campo_personalizado16", key: 'campo_personalizado16', width: 25 })
            }
          }
           if(element.campo_personalizado17){
            if (columns.filter(e => e.header === 'campo_personalizado17').length == 0) {
              
              columns.push({ header: "campo_personalizado17", key: 'campo_personalizado17', width: 25 })
            }
          }
           if(element.campo_personalizado18){
            if (columns.filter(e => e.header === 'campo_personalizado18').length == 0) {
              
              columns.push({ header: "campo_personalizado18", key: 'campo_personalizado18', width: 25 })
            }
          }
           if(element.campo_personalizado19){
            if (columns.filter(e => e.header === 'campo_personalizado19').length == 0) {
              
              columns.push({ header: "campo_personalizado19", key: 'campo_personalizado19', width: 25 })
            }
          }
           if(element.campo_personalizado20){
            if (columns.filter(e => e.header === 'campo_personalizado20').length == 0) {
              
              columns.push({ header: "campo_personalizado20", key: 'campo_personalizado20', width: 25 })
            }
          }


          
      });
    }*/

    await this.setState({ columnsExcell: columns })
    return columns;
  }




  getCalleds = async (filter = '', page = 1) => {

    const intl = this.props.intl;
    this.setState({ loading: true, exportList: [], showDownloadButton: false });
    
    var list
    //checando se a opção de selecionar todos os campos personalizados está marcada
    if(this.state.allCustomFields == true){
      list = this.state.CustomFields;
    }
    else{
      list = this.state.customFieldId;
    }
     
    PapiroConsole.log(list)
    PapiroConsole.log("list aqui")


    var customfieldids = "";
    var customfieldNames = []

    if (list != null && list != "") {


      for (let value in list) {

        customfieldids = list[value].id + "," + customfieldids;

        customfieldNames.unshift(list[value].name)

      }


      if (customfieldids.length > 0) {
        customfieldids = customfieldids.substr(0, customfieldids.length - 1);
      }


      PapiroConsole.log(customfieldids)
      PapiroConsole.log("customfieldids")

    }

    var status = ""
      if (this.state.statusIds != null && this.state.statusIds.length > 0) {
        let ids = this.state.statusIds.map(c => c.id);
        status = ids.join(',');
      }
    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

    if (dateStart > dateEnd) {
      const { intl } = this.props
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });
    }
    else {
      let url = `/report/calledsorganizations/paginate?page=${page}&org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&customfieldids=${customfieldids}&status=${status}&allorganizationschecked=${this.state.allOrganizations}`



      this.setState({ showGrid: true })

      var writing = ""

      ApiReports.get(url)
        .then(result => {
          if (result.data.success) {
            PapiroConsole.log("result aqui formatted")
            PapiroConsole.log(result.data)
            let count = result.data.count
            this.setState({ count: count })
            this.setPagination(count, page)

            const formattedList = result.data.data;



            this.setState({ calledsList: formattedList, loading: false })

            this.getColumns(formattedList, customfieldNames)

          } else {
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
          }
        })
        .catch(err => {

          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(err)
        })

    }


  }

  getMessageExcellBadUseFlag = (message, status) => {
    const intl = this.props.intl;
    let writing = ""
    if (status === intl.formatMessage({ id: "finished" })) {
      if (message === true)
        writing = intl.formatMessage({ id: "yes" })
      else if (message === false)
        writing = intl.formatMessage({ id: "no" })
    } else
      writing = intl.formatMessage({ id: "undefined" })

    return writing
  }


  getCalledsToPdf = (filter = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true })


    let list = this.state.customFieldId;
    PapiroConsole.log(list)
    PapiroConsole.log("list aqui")


    var customfieldids = "";

    if (list != null && list != "") {


      for (let value in list) {
        customfieldids = list[value].id + "," + customfieldids;

      }
      if (customfieldids.length > 0) {
        customfieldids = customfieldids.substr(0, customfieldids.length - 1);
      }


      PapiroConsole.log(customfieldids)
      PapiroConsole.log("customfieldids")

    }



    let url = `/techinicalteams/getexcell?org=${this.state.OrganizationFilter}}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&customfieldids=${customfieldids}`

    Api.get(url)
      .then(async (result) => {
        if (result.data.success) {
          PapiroConsole.log(result)
          PapiroConsole.log("result")
          await this.setState({ showHeader: true })
          const formattedList = result.data.data.map(item => {

            return {
              ...item,
              Name: item.name,
              TotalUsers: item.total_users,
              Organizacoes: item.organizacoes,
            }
          })

          const intl = this.props.intl
          const doc = new jsPDF("p", "mm", "a4")
          const header = document.getElementById('header-pdf')
          let headerCanvas = await html2canvas(header)
          const headerImg = headerCanvas.toDataURL('image/png')
          doc.addImage(headerImg, 'PNG', 0, 0)

          doc.autoTable({
            body: formattedList,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
              { header: intl.formatMessage({ id: "name" }), dataKey: 'Name' },
              { header: intl.formatMessage({ id: "total.users" }), dataKey: 'TotalUsers' },
              { header: intl.formatMessage({ id: "menu.organizations" }), dataKey: 'Organizacoes' },


            ],
            columnStyles: {
              Name: {
                columnWidth: 30
              },
              TotalUsers: {
                columnWidth: 30
              },
              Organizacoes: {
                columnWidth: 30
              },

            }
          })

          doc.save("relatorio-times-enviados.pdf")
          this.setState({ loading: false, showHeader: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    if (this.state.showDownloadButton) {
      this.setState({ showDownloadButton: false });
      return false;
    }
    this.setState({ loading: true });
    await this.getCalleds(this.props.location.state)



  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    PapiroConsole.log("state aqui")
    PapiroConsole.log(this.state.OrganizationFilter)







    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>



                  <Grid item xs={12} sm={6} md={4}  >
                    <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} fullWidth={true} required />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} fullWidth={true} required />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.OrgsList} stateName='OrganizationFilter' changeSelect={this.changeValues} disabled={this.state.allOrganizations} />
                    
                    <SimpleCheckBox label={intl.formatMessage({ id: "select.all.organizations" })} name='allOrganizations' stateName='allOrganizations' changeSelect={this.changeValues}  />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoCompleteMultipleCustomFieldPersonalized label={intl.formatMessage({ id: "organization.custom.fields" })} options={this.state.CustomFields} stateName='customFieldId' changeSelect={this.changeValues} selected={this.state.customFieldId}  disabled={this.state.allCustomFields}/>
                    <SimpleCheckBox label={intl.formatMessage({ id: "select.all.custom.fields" })} name='allCustomFields' stateName='allCustomFields' changeSelect={this.changeValues}  />
                  </Grid>
                  {/* <Grid item xs={1} sm={1} md={0.5} style={{ marginTop: '15px' }}>
                    <Tooltip title={this.props.intl.formatMessage({ id: "maximum.choices.custom.field" })}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4} >
                  <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName='statusIds' changeSelect={this.changeValues} selected={this.state.statusIds} />
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>


            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFileNew} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}
              {this.state.showDownloadButton && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <CsvDownloadButton
                  data={this.state.exportList}
                  filename={`./${Date.now()}_relatorio_chamados_organizacao.xlsx`}
                  delimiter={";"}
                  headers={this.state.exportColumns}
                  style={{ //pass other props, like styles
                    boxShadow: "inset 0px 1px 0px 0px #277afe",
                    background: "linear-gradient(to bottom, #277afe 5%, #277afe 100%)",
                    backgroundColor: "#277afe",
                    borderRadius: "6px",
                    border: "1px solid #277afe",
                    display: "inline-block",
                    cursor: "pointer", "color": "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #277afe"
                  }}
                >
                  {<FormattedMessage id="download" />}
                </CsvDownloadButton>
              </Grid>}

              {5 == 2 && this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getCalledsToPdf} >
                  {<FormattedMessage id="dash.pdf" />}
                </Button>
              </Grid>}
            </Grid>
          </form>

          {
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  this.state.columns
                }
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </>
          }
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />
            {this.state.showHeader &&
              <Grid container>
                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }}>
                  <Card >
                    <CardContent>
                      <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calleds.organizations.report" />}</span>
                      <Divider />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            }

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="calleds.organizations.report" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>


            {tableList()}


            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

CalledsOrganizationsReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledsOrganizationsReport)))
CalledsOrganizationsReport.contextType = AbilityContext