import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';

import Input from '../Input/Input';
import { useBaseInspector } from './useBaseInspector';

interface Props {
  cell: shapes.app.ResponseOrientation;
}

const cellProps = {
  label: ['attrs', 'label', 'text'],
  description: ['attrs', 'description', 'text'],
};

const ResponseOrientationInspector = (props: Props): ReactElement => {

    const { cell } = props;

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <label>Descrição
                <Input type="text"
                       placeholder="Enter description"
                       value={description}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                />
            </label>
        </>
    );
};

export default ResponseOrientationInspector;
