import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardContent, CircularProgress, Container, CssBaseline, Grid, Modal, Pagination, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from '../../../../components/material-snackbars/index.js';
import MyMaterialTable from '../../../../components/table';
import { AbilityContext } from '../../../../config/ability-context';
import JointPlusChatbot from '../../../../joint-plus/apps/Chatbot/ReactReduxTs/src/JointPlusChatbot';
import * as componentActions from '../../../../redux/actions/chatbotflows.js';
import * as ChatbotFlowService from "../../../../services/2talk/chatbot-flow.service.js";
import Api from '../../../../services/api.js';
import { Overlay } from '../../../../styles/global.js';
import { Styles } from '../../../../styles/material-styles';
import { PAGE_ADD, PAGE_EDIT } from "./page-type.js";

const ListChatbotFlow = (props) => {

  const { classes, chatbots } = props;

  const urlProjectChatbotFlow = 'http://localhost:3002/';

  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const abilityContext = useContext(AbilityContext);

  const [resultList, setResultList] = useState([]);

  const [statePage, setStatePage] = useState({
    loading: false,
    openNotification: false,
    notificationVariant: "error",
    notificationMessage: "",
    dataFilter: false
  });

  const [statePagination, setStatePagination] = useState({
    totalPages: 0,
    page: 1,
    pageStart: 0,
    pageEnd: 0,
    count: 0
  });

  const [currentIdChatbotFlow, setCurrentIdChatbotFlow] = useState(null);

  const handleOpenChatbotFlow = (id) => {
    setCurrentIdChatbotFlow(id);
    handleOpen();
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSaveJsonFlow = () => {
    console.log("@log >> chatbot json", localStorage.getItem("@mexx-flows-chatbot"));

    //const json = getJson(chatbotId);
    //console.log("@log >> save json >> ", json, chatbotId)
  }

  const getJson = (id) => {
    /*const storedValue = JSON.parse(localStorage.getItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY) || '[]');
    const chatbot = storedValue.find(item => item.id == id);

    console.log("@log >> chatbot json", localStorage.getItem("@mexx-flows-chatbot"));

    return chatbot ? chatbot.json : null;*/
  }

  const updateJson = (id, json) => {
    /*const storedValue = JSON.parse(localStorage.getItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY));
    const index = storedValue.findIndex(item => item.id == id);

    if (index !== -1) {
      storedValue[index].json = json;
    } else {
      storedValue.push({ id, json });
    }

    localStorage.setItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY, JSON.stringify(storedValue));*/
  }

  const fetchDataNotSuccess = (result) => {
    setStatePage({
      loading: false, 
      openNotification: true, 
      notificationVariant: "error",
      notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    })
  }

  const fetchDataThrowError = (err) => {
    setStatePage({
      loading: false, 
      openNotification: true, 
      notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    })
  }

  const onChangePaginate = (e, page) => {
    setStatePagination(prevState => ({...prevState, page}));
    getItens(page);
  };

  const getItens = async (page = 1) => {
    setStatePage(prevState => ({...prevState, loading: true}));

    try {
      const chatbotId = chatbots?.chatbot?.id;
      const output = await ChatbotFlowService.getAllPaginate(page, chatbotId);

      if (output.success) {
        const isEmptyArray = output.data.length === 0;
        const itemCount = output.count;
        const formattedList = output.data.map(item => ({ ...item }));

        setResultList(formattedList);

        setStatePage(prevState => ({...prevState, dataFilter: isEmptyArray}))

        setStatePagination(prevState => ({
          ...prevState,
          count: itemCount,
        }));

        setPagination(itemCount, page);
      } else {
        fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      setStatePage(prevState => ({...prevState, loading: false}));
    }
  };

  const setPagination = (count, page) => {
    const totalPage = Math.ceil(count / 10);

    setStatePagination(prevState => ({
      ...prevState,
      totalPages: totalPage,
    }));

    if (count > 0) {
      if (page === 1) {
        setStatePagination(prevState => ({
          ...prevState,
          pageStart: 1,
          pageEnd: 10
        }));
      } else {
        const start = ((page - 1) * 10) + 1;

        setStatePagination(prevState => ({
          ...prevState,
          pageStart: start,
          pageEnd: count >= page * 10 ? page * 10 : count
        }));
      }
    } else {
      setStatePagination(prevState => ({
        ...prevState,
        totalPages: 0,
        page: 1,
        count: 0
      }));
    }
  };

  const deleteItem = async (data, resolve) => {
    try {
      const output = await ChatbotFlowService.deleteById(data.id);

      if (output.success) {
        setStatePage(prevState => ({
          ...prevState,
          notificationVariant: 'success', 
          notificationMessage: intl.formatMessage({ id: "delete.success" }) 
        }))
        setResultList([]);
        await getItens();
      } else {
        fetchDataNotSuccess(output);
        Api.kickoff(output);
      }
      resolve();

    } catch (err) {
      resolve();
      fetchDataThrowError(err);
      Api.kickoff(err);
    }
  };
  
  const closeNotification = () => {
    setStatePage(prevState => ({...prevState, openNotification: false}));
  };

  useEffect(() => {
    if (location.state === "reload") {
      getItens();
    }
  }, [location.state]);

  useEffect(() => {
    getItens();
  }, []);
  

  return (
    <>
      <div style={{marginTop: '32px', minWidth: '850px'}}>
        <CssBaseline />

        <Grid container className={classes.mb20} spacing={0}>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            className={classes.button}
            startIcon={<AddIcon />}
            disabled={!abilityContext.can('Add', 'Group')}
            onClick={() => props.onChangePage(PAGE_ADD)}
          >
            <FormattedMessage id="add" />
          </Button>
        </Grid>

        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} sm={12}>
            <Card className="background-title-search">
              <CardContent>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={5} md={3} lg={6}>
                    <Typography variant="h5">
                      <FormattedMessage id="chatbot-flow.header" />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <MyMaterialTable
          title=""
          rowClick={(event, rowData) => {
            dispatch(componentActions.addCurrent(rowData));
            props.onChangePage(PAGE_EDIT)
          }}
          columns={[
            { title: intl.formatMessage({ id: "id" }), field: 'id' },
            { title: intl.formatMessage({ id: "name" }), field: 'name' },
            { 
              title: intl.formatMessage({ id: "flowdefault" }), 
              render: (row) => {
                return row?.flowdefault ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })
              }
            },
            { 
              title: "Fluxograma", 
              field: "",
              render: row =>                 
                  (
                    <Button 
                        variant="contained" 
                        onClick={(event) => {
                            event.stopPropagation();
                            handleOpenChatbotFlow(row.id)
                        }} 
                        color="primary"
                        style={{borderRadius: '100%', height: '40px', width: '40px'}}
                    >
                      <AccountTreeIcon/>
                    </Button>
                  )
            },
            { title: "", field: "" },
            { title: "", field: "" },
          ]}
          data={resultList}
          deleteItem={deleteItem}
        />

        <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
          <Grid item xs={12} md={3}>
            <Pagination count={statePagination.totalPages} page={statePagination.page} onChange={onChangePaginate} />
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
            {statePagination.pageStart} &nbsp;
            <FormattedMessage id="to" /> &nbsp;
            {statePagination.pageEnd} &nbsp;
            <FormattedMessage id="of" /> &nbsp;
            {statePagination.count} &nbsp;
            <FormattedMessage id="register" />
          </Grid>
        </Grid>
      </div>

      {statePage.loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={statePage.notificationVariant}
        message={statePage.notificationMessage}
        isOpen={statePage.openNotification}
        toClose={closeNotification}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{width: '100vw'}}
      >
        <Box style={{ position: 'relative' }}>
          <div style={{ paddingTop: '10px', width:'100vw', margin: '0 auto' }}>
            <Container component='main' maxWidth='false'>
              <div style={{ marginTop: 0 }}>
                {/*<ChatbotFlowInit id={currentIdChatbotFlow}/>*/}
                <JointPlusChatbot id={currentIdChatbotFlow}/>
              </div>
              <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px', marginTop: '4px'}}>
                <Button 
                  type='button'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={handleClose}
                >
                  {<FormattedMessage id="close" />}
                </Button>
              </div>
            </Container>
        </div>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  chatbots: state.chatbots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ListChatbotFlow)));